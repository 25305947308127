declare global {
	interface Window {
		envOverride: Record<string, string>;
	}
}

const PREVIEW_DOMAIN = '.pr.aws.vxlive.net';

export const loadEnvOverride = (onLoad: () => void) => {
	if (window.location.hostname.indexOf(PREVIEW_DOMAIN) !== -1) {
		(async () => {
			try {
				const response = await fetch(`/envOverride.json`, {cache: 'no-store'});

				if (!response.ok) {
					throw new Error(response.statusText);
				}

				window.envOverride = await response.json();
			} catch (error) {
				console.error(error);
			}

			onLoad();
		})();
	} else {
		onLoad();
	}
};
