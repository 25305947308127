import 'react-app-polyfill/stable';
import 'reflect-metadata';
import 'intersection-observer';
import loadable from '@loadable/component';
import React from 'react';
import {createRoot} from 'react-dom/client';

import {loadEnvOverride} from 'src/Services/EnvService/envOverride';

import * as serviceWorker from './serviceWorker';

import './index.css';

const App = loadable(() => import('./App'));

loadEnvOverride(() => {
	createRoot(document.getElementById('bodycontainer')!).render(<App />);
});

serviceWorker.unregister();
